<template>
    <section>
        <blank-page-content />
    </section>
</template>

<script>
    import BlankPageContent from "../../layout/components/BlankPageContent";
    export default {
        name: 'Profile',
        components: {BlankPageContent},
    }
</script>
